<template>
  <div class="modal-backdrop" v-if="isVisible" @click.self="close">
    <div class="modal-content dmca-modal">
      <div class="modal-header">
        <h3>DMCA Policy</h3>
        <button class="close-button" @click="close">&times;</button>
      </div>

      <div class="modal-body">
        <div class="dmca-content">
          <h4>1. Introduction</h4>
          <p>FaceSpy respects the intellectual property rights of others and expects its users to do the same. In accordance with the Digital Millennium Copyright Act of 1998 ("DMCA"), we will respond expeditiously to claims of copyright infringement that are reported to our designated copyright agent.</p>
          
          <h4>2. Reporting Copyright Infringement</h4>
          <p>If you believe that your copyrighted work has been copied in a way that constitutes copyright infringement and is accessible on FaceSpy, please send a notification containing the following information to our copyright agent:</p>
          <ol>
            <li>A physical or electronic signature of a person authorized to act on behalf of the owner of the copyright that has been allegedly infringed</li>
            <li>Identification of the copyrighted work claimed to have been infringed</li>
            <li>Identification of the material that is claimed to be infringing and where it is located on our service</li>
            <li>Information reasonably sufficient to permit us to contact you, such as your address, telephone number, and email address</li>
            <li>A statement that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or law</li>
            <li>A statement, made under penalty of perjury, that the above information is accurate, and that you are the copyright owner or are authorized to act on behalf of the owner</li>
          </ol>
          
          <h4>3. DMCA Process</h4>
          <p>Upon receipt of a compliant DMCA notice, our policy is to:</p>
          <ul>
            <li>Remove or disable access to the infringing content</li>
            <li>Notify the content provider, member, or user that we have removed or disabled access to the content</li>
            <li>Terminate, in appropriate circumstances, users and account holders who are repeat infringers</li>
          </ul>
          
          <h4>4. Counter-Notification</h4>
          <p>If you believe that your content that was removed is not infringing, or that you have the authorization to post and use the content from the copyright owner, the copyright owner's agent, or pursuant to the law, you may send a counter-notice containing the following information to our copyright agent:</p>
          <ol>
            <li>Your physical or electronic signature</li>
            <li>Identification of the content that has been removed and where it was posted</li>
            <li>A statement under penalty of perjury that you have a good faith belief that the content was removed as a result of mistake or misidentification</li>
            <li>Your name, address, telephone number, and email address, and a statement that you consent to the jurisdiction of the federal court in the district where you reside, and that you will accept service of process from the person who provided notification of the alleged infringement</li>
          </ol>
          <p>If a counter-notice is received, we may send a copy to the original complaining party informing them that we may replace the removed content or cease disabling it within 10-14 business days. Unless the copyright owner files an action seeking a court order against the content provider, member or user, the removed content may be replaced or access to it restored after 10 to 14 business days or more after receipt of the counter-notice, at our discretion.</p>
          
          <h4>5. Repeat Infringer Policy</h4>
          <p>In accordance with the DMCA and other applicable laws, we have adopted a policy of terminating, in appropriate circumstances and at our sole discretion, users who are deemed to be repeat infringers. We may also at our sole discretion limit access to FaceSpy and/or terminate the accounts of any users who infringe any intellectual property rights of others, whether or not there is any repeat infringement.</p>
          
          <h4>6. Changes to This DMCA Policy</h4>
          <p>We may update our DMCA Policy from time to time. We will notify you of any changes by posting the new DMCA Policy on this page.</p>
          
          <h4>7. Contact Us</h4>
          <p>If you have any questions about our DMCA Policy or to submit a DMCA notice, please contact our designated copyright agent at:</p>
          <p>
            DMCA Agent<br>
            FaceSpy<br>
            Email: support@face-spy.com
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '../services/EventBus';
import ModalService from '../services/ModalService';
import { EVENTS } from '../services/ModalService';

export default {
  name: "DmcaModal",
  data() {
    return {
      isVisible: false
    };
  },
  methods: {
    close() {
      this.isVisible = false;
      ModalService.hideDmca();
    }
  },
  mounted() {
    // Listen for show/hide events
    EventBus.on(EVENTS.DMCA, (visible) => {
    //   console.log("DMCA Policy visibility changed:", visible);
      this.isVisible = visible;
    });
  },
  beforeUnmount() {
    // Clean up event listeners
    EventBus.off(EVENTS.DMCA);
  }
};
</script>

<style>
/* Specific styles for DMCA modal */
.dmca-content h4 {
  color: #333;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 1.2rem;
}

.dmca-content p, 
.dmca-content li {
  color: #666;
  line-height: 1.6;
  margin-bottom: 10px;
}

.dmca-content ul,
.dmca-content ol {
  padding-left: 20px;
  margin-bottom: 15px;
}

.dmca-content ol {
  list-style-type: decimal;
}

.dmca-content strong {
  font-weight: 600;
  color: #555;
}
</style> 