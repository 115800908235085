/**
 * AuthStore - Simple auth state management for the application
 * This provides backward compatibility with code that references AuthStore
 */
class AuthStore {
  constructor() {
    this.user = null;
    this.tokens = {
      accessToken: null,
      refreshToken: null,
      expiresAt: null,
    };
    this.loginSource = "navbar";
  }

  initialize() {
    // Load auth data from localStorage if available
    const tokenExpiry = localStorage.getItem("tokenExpiry");
    if (tokenExpiry && parseInt(tokenExpiry) > Date.now()) {
      this.user = {
        id: localStorage.getItem("userId"),
        email: localStorage.getItem("userEmail"),
        name: localStorage.getItem("userName"),
        photoUrl: localStorage.getItem("userPhoto"),
      };

      this.tokens = {
        accessToken: localStorage.getItem("accessToken"),
        refreshToken: localStorage.getItem("refreshToken"),
        expiresAt: parseInt(localStorage.getItem("tokenExpiry")),
      };

      this.loginSource = localStorage.getItem("loginSource") || "navbar";
    }
  }

  setUser(user) {
    this.user = user;
    localStorage.setItem("userId", user.id);
    localStorage.setItem("userEmail", user.email);
    localStorage.setItem("userName", user.name);
    localStorage.setItem("userPhoto", user.photoUrl);
  }

  setTokens(tokens) {
    this.tokens = tokens;
    localStorage.setItem("accessToken", tokens.accessToken);
    localStorage.setItem("refreshToken", tokens.refreshToken);
    localStorage.setItem("tokenExpiry", tokens.expiresAt);
  }

  setLoginSource(source) {
    this.loginSource = source;
    localStorage.setItem("loginSource", source);
  }

  clearAuth() {
    // Clear local storage
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("tokenExpiry");
    localStorage.removeItem("userId");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("userName");
    localStorage.removeItem("userPhoto");
    localStorage.removeItem("loginSource");

    // Clear memory
    this.user = null;
    this.tokens = {
      accessToken: null,
      refreshToken: null,
      expiresAt: null,
    };
    this.loginSource = "navbar";
  }

  isLoggedIn() {
    return Boolean(
      this.tokens.accessToken && this.tokens.expiresAt > Date.now()
    );
  }

  getAuthHeader() {
    return this.tokens.accessToken ? `Bearer ${this.tokens.accessToken}` : null;
  }
}

export default new AuthStore();
