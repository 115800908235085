<template>
  <div class="settings-page">
    <div class="settings-container">
      <h1>Account Settings</h1>
      
      <div class="settings-section">
        <h2>Subscription Management</h2>
        
        <div v-if="isLoading" class="loading-state">
          <div class="spinner"></div>
          <p>Loading your subscription details...</p>
        </div>
        
        <!-- Premium Subscription Details -->
        <div v-else-if="isPremium || isCancelled" class="subscription-card premium">
          <div class="subscription-header">
            <i class="fa fa-star"></i>
            <h3>Premium Subscription</h3>
            <div class="subscription-badge" :class="{ cancelled: isCancelled }">
              {{ isCancelled ? 'Cancelled' : 'Active' }}
            </div>
          </div>
          
          <div class="subscription-details">
            <div class="detail-item">
              <span class="detail-label">Searches Used This Month:</span>
              <span class="detail-value">{{ searchesUsed }}</span>
            </div>
            <div class="detail-item">
              <span class="detail-label">{{ isCancelled ? 'Active Until' : 'Subscription Renews' }}:</span>
              <span class="detail-value">{{ formatPremiumDate(premiumUntil) }}</span>
            </div>
            <div v-if="isCancelled" class="detail-item cancelled-info">
              <p>Your subscription has been cancelled but remains active until the end date.</p>
            </div>
          </div>
          
          <div class="subscription-actions">
            <button v-if="isCancelled" class="button reactivate" @click="reactivateSubscription" :disabled="isReactivating">
              <i v-if="isReactivating" class="fa fa-spinner fa-spin"></i>
              <span v-else>Reactivate Subscription</span>
            </button>
            <button v-else class="button cancel" @click="showCancelConfirmation = true" :disabled="isCancelling">
              <i v-if="isCancelling" class="fa fa-spinner fa-spin"></i>
              <span v-else>Cancel Subscription</span>
            </button>
          </div>
          
          <!-- Cancelation Confirmation Dialog -->
          <div v-if="showCancelConfirmation" class="cancel-dialog">
            <div class="cancel-dialog-content">
              <h4>Are you sure you want to cancel?</h4>
              <p>Your premium benefits will continue until the end of your current billing period.</p>
              <div class="dialog-actions">
                <button class="button secondary" @click="showCancelConfirmation = false">Keep Subscription</button>
                <button class="button danger" @click="cancelSubscription" :disabled="isCancelling">
                  <i v-if="isCancelling" class="fa fa-spinner fa-spin"></i>
                  <span v-else>Confirm Cancellation</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        
        <!-- Free Account -->
        <div v-else class="subscription-card free">
          <div class="subscription-header">
            <i class="fa fa-user"></i>
            <h3>Free Account</h3>
          </div>
          
          <div class="subscription-details">
            <div class="detail-item">
              <span class="detail-label">Features:</span>
              <span class="detail-value">Basic search with truncated results</span>
            </div>
          </div>
          
          <div class="subscription-actions">
            <button class="button upgrade" @click="upgrade">
              <i class="fa fa-star"></i> Upgrade to Premium
            </button>
          </div>
        </div>
      </div>
      
      <div class="settings-section">
        <h2>Account Information</h2>
        <div class="user-info">
          <div class="user-avatar">
            <img 
              :src="getProfileImageUrl()" 
              alt="Profile" 
              class="profile-picture" 
              @error="handleImageError"
            >
          </div>
          <div class="user-details">
            <div class="detail-item">
              <span class="detail-label">Name:</span>
              <span class="detail-value">{{ user.name }}</span>
            </div>
            <div class="detail-item">
              <span class="detail-label">Email:</span>
              <span class="detail-value">{{ user.email }}</span>
            </div>
            <div class="detail-item">
              <span class="detail-label">Account Type:</span>
              <span class="detail-value">{{ isPremium ? 'Premium' : 'Free' }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoogleAuthService from "@/services/GoogleAuthService";
import PremiumService from "@/services/PremiumService";
import EventBus from "@/services/EventBus";

export default {
  name: "Settings",
  data() {
    return {
      isLoading: true,
      isPremium: false,
      premiumUntil: null,
      searchesUsed: 0,
      showCancelConfirmation: false,
      isCancelling: false,
      error: null,
      isCancelled: false,
      isReactivating: false
    };
  },
  computed: {
    user() {
      return GoogleAuthService.getUser() || { name: "User", photoUrl: "", email: "" };
    },
    isLoggedIn() {
      return GoogleAuthService.isAuthenticated();
    }
  },
  methods: {
    formatPremiumDate(date) {
      return PremiumService.formatPremiumDate(date);
    },
    async loadSubscriptionDetails() {
      if (!this.isLoggedIn) {
        this.$router.push('/');
        return;
      }
      
      try {
        this.isLoading = true;
        
        // Load premium status
        await PremiumService.loadPremiumStatus();
        this.isPremium = PremiumService.isPremiumUser();
        this.isCancelled = PremiumService.isSubscriptionCancelled();
        this.premiumUntil = PremiumService.getPremiumUntil();
        
        this.isLoading = false;
      } catch (error) {
        console.error('Failed to load subscription details:', error);
        this.error = 'Could not load subscription details';
        this.isLoading = false;
      }
    },
    upgrade() {
      PremiumService.redirectToCheckout();
    },
    async cancelSubscription() {
      if (!this.isPremium) return;
      
      this.isCancelling = true;
      try {
        const success = await PremiumService.cancelSubscription();
        if (success) {
          this.showCancelConfirmation = false;
          // In a real implementation, we might set a "cancelled but active until end of period" state
          // For now, we'll just reload subscription details
          await this.loadSubscriptionDetails();
        } else {
          this.error = 'Failed to cancel subscription';
        }
      } catch (error) {
        console.error('Error cancelling subscription:', error);
        this.error = 'Failed to cancel subscription';
      } finally {
        this.isCancelling = false;
      }
    },
    async reactivateSubscription() {
      if (!this.isCancelled) return;
      
      this.isReactivating = true;
      try {
        const success = await PremiumService.reactivateSubscription();
        if (success) {
          this.isCancelled = false;
          await this.loadSubscriptionDetails();
        } else {
          this.error = 'Failed to reactivate subscription';
        }
      } catch (error) {
        console.error('Error reactivating subscription:', error);
        this.error = 'Failed to reactivate subscription';
      } finally {
        this.isReactivating = false;
      }
    },
    setupEventListeners() {
      // Listen for premium status changes
      EventBus.on("premium-status-change", (isPremium) => {
        this.isPremium = isPremium;
        this.premiumUntil = PremiumService.getPremiumUntil();
      });
      
      // Listen for subscription cancellation status changes
      EventBus.on("premium-cancelled", (premiumUntil) => {
        this.isCancelled = true;
        this.isPremium = false;
        this.premiumUntil = premiumUntil;
      });
    },
    getProfileImageUrl() {
      let url = '';
      
      // Use the user's photo URL if available, or generate a placeholder
      if (this.user.photoUrl && this.user.photoUrl.trim() !== '') {
        url = this.user.photoUrl;
      } else {
        // Generate a user avatar with first initial and full name
        const name = this.user.name || '?';
        url = `https://ui-avatars.com/api/?name=${encodeURIComponent(name)}&background=4A74DC&color=fff&size=100`;
      }
      
      return url;
    },
    handleImageError(event) {
      // Generate a user avatar with first initial and full name
      const name = this.user.name || '?';
      event.target.src = `https://ui-avatars.com/api/?name=${encodeURIComponent(name)}&background=4A74DC&color=fff&size=100`;
      
      // Remove the error handler to prevent potential loops
      event.target.removeEventListener('error', this.handleImageError);
    }
  },
  async mounted() {
    // Set up event listeners
    this.setupEventListeners();
    
    // Load subscription details
    await this.loadSubscriptionDetails();
  },
  beforeUnmount() {
    // Clean up event listeners
    EventBus.off('premium-status-change');
    EventBus.off('premium-cancelled');
  }
};
</script>

<style scoped>
.settings-page {
  padding: 40px 20px;
  background: linear-gradient(135deg, rgba(22, 22, 26, 0.9) 0%, rgba(16, 16, 22, 0.9) 100%);
  min-height: calc(100vh - 60px);
}

.settings-container {
  max-width: 800px;
  margin: 0 auto;
  color: white;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  text-align: center;
  background: linear-gradient(135deg, #4a74dc 0%, #c33c65 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.settings-section {
  background-color: rgba(30, 30, 38, 0.85);
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  padding: 30px;
  margin-bottom: 30px;
}

h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 10px;
}

.loading-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(255, 255, 255, 0.2);
  border-top: 4px solid #4a74dc;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 15px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.subscription-card {
  background-color: rgba(40, 40, 50, 0.5);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.subscription-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.subscription-header i {
  font-size: 24px;
  margin-right: 10px;
}

.subscription-header h3 {
  font-size: 1.4rem;
  margin: 0;
  flex-grow: 1;
}

.subscription-badge {
  display: inline-block;
  padding: 3px 10px;
  border-radius: 12px;
  background-color: #4CAF50;
  color: white;
  font-size: 0.8rem;
  font-weight: 600;
}

.subscription-badge.cancelled {
  background-color: #ff9800;
}

.subscription-details {
  margin-bottom: 20px;
}

.detail-item {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}

.detail-label {
  font-weight: 600;
  width: 200px;
  color: rgba(255, 255, 255, 0.7);
}

.detail-value {
  font-weight: 500;
}

.subscription-actions {
  display: flex;
  justify-content: flex-end;
}

.button {
  padding: 10px 20px;
  border-radius: 6px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: all 0.3s ease;
}

.button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.button.upgrade {
  background: linear-gradient(135deg, #4a74dc 0%, #c33c65 100%);
  color: white;
}

.button.cancel {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
}

.button.secondary {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
}

.button.danger {
  background-color: #e53935;
  color: white;
}

.button:hover:not(:disabled) {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.cancel-dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.cancel-dialog-content {
  background-color: #1e1e26;
  border-radius: 8px;
  padding: 30px;
  max-width: 500px;
  width: 90%;
  text-align: center;
}

.cancel-dialog-content h4 {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.cancel-dialog-content p {
  margin-bottom: 25px;
  color: rgba(255, 255, 255, 0.7);
}

.dialog-actions {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.user-info {
  display: flex;
  align-items: flex-start;
}

.user-avatar {
  margin-right: 30px;
}

.profile-picture {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid rgba(255, 255, 255, 0.2);
}

.user-details {
  flex-grow: 1;
}

.cancelled-info {
  margin-top: 10px;
  padding: 10px;
  background-color: rgba(255, 152, 0, 0.1);
  border-left: 3px solid #ff9800;
  border-radius: 4px;
}

.cancelled-info p {
  margin: 0;
  font-size: 0.9rem;
  color: #555;
}

.button.reactivate {
  background-color: #2196F3;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  font-weight: 600;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 160px;
}

.button.reactivate:hover {
  background-color: #0b7dda;
}

@media (max-width: 768px) {
  .user-info {
    flex-direction: column;
    align-items: center;
  }
  
  .user-avatar {
    margin-right: 0;
    margin-bottom: 20px;
  }
  
  .detail-item {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .detail-label {
    width: 100%;
    margin-bottom: 5px;
  }
  
  .dialog-actions {
    flex-direction: column;
  }
  
  .dialog-actions .button {
    width: 100%;
    margin-bottom: 10px;
  }
}
</style> 