<template>
  <div class="modal-backdrop" v-if="isVisible" @click.self="close">
    <div class="modal-content how-it-works-modal">
      <div class="modal-header">
        <h3>How FaceSpy Works</h3>
        <button class="close-button" @click="close">&times;</button>
      </div>

      <div class="modal-body">
        <div class="how-it-works-content">
          <div class="intro-section">
            <p class="intro-text">FaceSpy uses advanced face recognition technology to help you find OnlyFans profiles that match your uploaded images. Here's how our service works:</p>
          </div>

          <div class="step-section">
            <h4><span class="step-number">1</span> Upload An Image</h4>
            <p>Simply drag and drop an image onto our uploader, paste from clipboard, or select a file from your device. We accept most common image formats (JPG, PNG, WEBP).</p>
            <div class="key-features">
              <div class="feature">
                <i class="fa fa-check-circle"></i>
                <span>Multiple upload methods</span>
              </div>
              <div class="feature">
                <i class="fa fa-check-circle"></i>
                <span>Image preview before processing</span>
              </div>
              <div class="feature">
                <i class="fa fa-check-circle"></i>
                <span>Private and secure</span>
              </div>
            </div>
          </div>
          
          <div class="step-section">
            <h4><span class="step-number">2</span> Face Detection</h4>
            <p>Our system automatically detects and extracts facial features from your uploaded image. This process creates a unique facial signature that will be used for matching.</p>
            <div class="key-features">
              <div class="feature">
                <i class="fa fa-check-circle"></i>
                <span>Advanced facial recognition</span>
              </div>
              <div class="feature">
                <i class="fa fa-check-circle"></i>
                <span>Works with various angles and lighting</span>
              </div>
              <div class="feature">
                <i class="fa fa-check-circle"></i>
                <span>Multiple faces can be detected in one image</span>
              </div>
            </div>
          </div>
          
          <div class="step-section">
            <h4><span class="step-number">3</span> Database Matching</h4>
            <p>The facial signature is compared against our extensive database of OnlyFans creator profiles. Our algorithm looks for matching facial features and ranks the results by similarity score.</p>
            <div class="key-features">
              <div class="feature">
                <i class="fa fa-check-circle"></i>
                <span>Database of millions of profiles</span>
              </div>
              <div class="feature">
                <i class="fa fa-check-circle"></i>
                <span>Continuously updated</span>
              </div>
              <div class="feature">
                <i class="fa fa-check-circle"></i>
                <span>High-precision matching</span>
              </div>
            </div>
          </div>
          
          <div class="step-section">
            <h4><span class="step-number">4</span> View Results</h4>
            <p>We present you with a list of potential matches, ranked by similarity. Free users can see blurred previews, while premium users get full access to uncensored results and direct links to profiles.</p>
            <div class="key-features">
              <div class="feature">
                <i class="fa fa-check-circle"></i>
                <span>Sorted by match confidence</span>
              </div>
              <div class="feature">
                <i class="fa fa-check-circle"></i>
                <span>Premium gets unlimited searches</span>
              </div>
              <div class="feature">
                <i class="fa fa-check-circle"></i>
                <span>Profile links and additional info</span>
              </div>
            </div>
          </div>

          <div class="premium-callout">
            <h4>Upgrade to Premium</h4>
            <p>Unlock unlimited searches, uncensored results, and direct links to profiles with our Premium plan.</p>
            <button class="premium-button" @click="upgradeToPremium">Upgrade Now</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '../services/EventBus';
import ModalService from '../services/ModalService';
import { EVENTS } from '../services/ModalService';
import GoogleAuthService from '../services/GoogleAuthService';
import PremiumService from '../services/PremiumService';

export default {
  name: "HowItWorksModal",
  data() {
    return {
      isVisible: false
    };
  },
  methods: {
    close() {
      this.isVisible = false;
      ModalService.hideHowItWorks();
    },
    upgradeToPremium() {
      // Close the modal first
      this.close();
      
      // Check if user is logged in
      if (!GoogleAuthService.isLoggedIn()) {
        // console.log("User not logged in, redirecting to sign in");
        // Save intent to directly go to checkout after login
        localStorage.setItem('postLoginRedirect', '/premium/checkout');
        // Use the same sign-in method as the navbar but with a different source
        GoogleAuthService.signIn('how-it-works-modal');
        return;
      }
      
      // If logged in, redirect to checkout directly
      PremiumService.redirectToCheckout();
    }
  },
  mounted() {
    // Listen for show/hide events
    EventBus.on(EVENTS.HOW_IT_WORKS, (visible) => {
      // console.log("How It Works visibility changed:", visible);
      this.isVisible = visible;
    });
  },
  beforeUnmount() {
    // Clean up event listeners
    EventBus.off(EVENTS.HOW_IT_WORKS);
  }
};
</script>

<style>
/* Specific styles for How It Works modal */
.how-it-works-modal .modal-body {
  padding: 0;
}

.how-it-works-content {
  padding: 20px;
  color: #666;
}

.intro-section {
  margin-bottom: 30px;
  text-align: center;
}

.intro-text {
  font-size: 1.1rem;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
}

.step-section {
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 8px;
  background-color: #f9fafc;
  border-left: 4px solid #4a74dc;
}

.step-section h4 {
  color: #333;
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
}

.step-number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: linear-gradient(135deg, #4a74dc 0%, #c33c65 100%);
  color: white;
  border-radius: 50%;
  margin-right: 10px;
  font-size: 1rem;
  font-weight: bold;
}

.key-features {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  gap: 10px;
}

.feature {
  display: flex;
  align-items: center;
  padding: 8px 15px;
  background-color: rgba(74, 116, 220, 0.1);
  border-radius: 20px;
  margin-right: 10px;
}

.feature i {
  color: #4a74dc;
  margin-right: 8px;
}

.premium-callout {
  margin: 40px 0;
  padding: 25px;
  border-radius: 8px;
  background: linear-gradient(135deg, rgba(74, 116, 220, 0.1) 0%, rgba(195, 60, 101, 0.1) 100%);
  text-align: center;
  border: 1px solid rgba(74, 116, 220, 0.2);
}

.premium-callout h4 {
  color: #333;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 1.4rem;
}

.premium-button {
  display: inline-block;
  margin-top: 15px;
  padding: 10px 25px;
  background: linear-gradient(135deg, #4a74dc 0%, #c33c65 100%);
  color: white;
  border-radius: 25px;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
}

.premium-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .key-features {
    flex-direction: column;
  }
  
  .feature {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
</style> 