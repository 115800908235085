<template>
  <footer class="site-footer">
    <div class="footer-container">
      <div class="footer-main">
        <div class="footer-section brand-section">
          <div class="footer-logo">
            <img src="@/assets/logo.svg" alt="FaceSpy Logo" class="footer-logo-image" />
            <span class="footer-logo-text">FaceSpy</span>
          </div>
          <p class="footer-tagline">OnlyFans Search by Face - Discover hidden profiles with advanced AI recognition technology.</p>
          <div class="social-links">
            <a href="#" class="social-link" aria-label="Facebook">
              <i class="fa fa-facebook"></i>
            </a>
            <a href="#" class="social-link" aria-label="Twitter">
              <i class="fa fa-twitter"></i>
            </a>
            <a href="#" class="social-link" aria-label="Instagram">
              <i class="fa fa-instagram"></i>
            </a>
            <a href="#" class="social-link" aria-label="LinkedIn">
              <i class="fa fa-linkedin"></i>
            </a>
          </div>
        </div>

        <div class="footer-section links-section">
          <h3 class="footer-heading">Quick Links</h3>
          <ul class="footer-links">
            <li><router-link to="/">Home</router-link></li>
            <!-- <li><router-link to="/premium">Premium</router-link></li> -->
            <li><router-link to="/settings">Settings</router-link></li>
            <!-- <li><a href="#">Blog</a></li> -->
            <!-- <li><a href="#">About Us</a></li> -->
          </ul>
        </div>

        <div class="footer-section links-section">
          <h3 class="footer-heading">Resources</h3>
          <ul class="footer-links">
            <li><a href="#" @click.prevent="showHowItWorks">How It Works</a></li>
            <li><a href="#" @click.prevent="showUserGuide">User Guide</a></li>
            <!-- <li><a href="#">API Documentation</a></li>
            <li><a href="#">Developers</a></li>
            <li><a href="#">Partners</a></li> -->
          </ul>
        </div>

        <div class="footer-section contact-section">
          <h3 class="footer-heading">Contact Us</h3>
          <ul class="contact-info">
            <li><i class="fa fa-envelope"></i> support@face-spy.com</li>
            <!-- <li><i class="fa fa-phone"></i> +1 (555) 123-4567</li>
            <li><i class="fa fa-map-marker"></i> 123 Tech Street, San Francisco, CA</li> -->
          </ul>
          <div class="newsletter">
            <p>Subscribe to our newsletter</p>
            <div class="newsletter-form">
              <input 
                v-model="newsletterEmail" 
                type="email" 
                placeholder="Your email" 
                class="newsletter-input"
                :class="{ 'error': emailError }" 
              />
              <button 
                class="newsletter-button" 
                @click="subscribeToNewsletter"
                :disabled="isSubscribing"
              >
                <span v-if="isSubscribing" class="mini-spinner"></span>
                <span v-else>Subscribe</span>
              </button>
            </div>
            <p v-if="emailError" class="newsletter-error">{{ emailError }}</p>
            <p v-if="subscriptionMessage" class="newsletter-success">{{ subscriptionMessage }}</p>
          </div>
        </div>
      </div>

      <div class="footer-bottom">
        <div class="copyright">
          &copy; {{ new Date().getFullYear() }} FaceSpy. All rights reserved.
        </div>
        <div class="legal-links">
          <a href="#" @click.prevent="showPrivacyPolicy">Privacy Policy</a>
          <a href="#" @click.prevent="showTermsOfService">Terms of Service</a>
          <a href="#" @click.prevent="showCookiePolicy">Cookie Policy</a>
          <a href="#" @click.prevent="showDmca">DMCA</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import ModalService from '../services/ModalService';
import axios from 'axios';
import { API_URL } from '../config';

export default {
  name: "Footer",
  data() {
    return {
      newsletterEmail: '',
      emailError: '',
      subscriptionMessage: '',
      isSubscribing: false
    };
  },
  methods: {
    showPrivacyPolicy() {
      // console.log("Showing privacy policy");
      ModalService.showPrivacyPolicy();
    },
    showTermsOfService() {
      // console.log("Showing terms of service");
      ModalService.showTermsOfService();
    },
    showCookiePolicy() {
      // console.log("Showing cookie policy");
      ModalService.showCookiePolicy();
    },
    showDmca() {
      // console.log("Showing DMCA policy");
      ModalService.showDmca();
    },
    showHowItWorks() {
      // console.log("Showing how it works");
      ModalService.showHowItWorks();
    },
    showUserGuide() {
      // console.log("Showing user guide");
      ModalService.showUserGuide();
    },
    async subscribeToNewsletter() {
      // Reset messages
      this.emailError = '';
      this.subscriptionMessage = '';
      
      // Basic validation
      if (!this.newsletterEmail) {
        this.emailError = 'Please enter your email';
        return;
      }
      
      // Email format validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(this.newsletterEmail)) {
        this.emailError = 'Please enter a valid email address';
        return;
      }
      
      // Submit subscription
      this.isSubscribing = true;
      
      try {
        const response = await axios.post(`${API_URL}/newsletter/subscribe`, {
          email: this.newsletterEmail
        });
        
        this.subscriptionMessage = response.data.message || 'Thank you for subscribing!';
        // Clear the email input on success
        this.newsletterEmail = '';
      } catch (error) {
        console.error('Newsletter subscription error:', error);
        this.emailError = error.response?.data?.detail || 'Failed to subscribe. Please try again.';
      } finally {
        this.isSubscribing = false;
      }
    },
    async submitContactForm() {
      try {
        await axios.post(`${API_URL}/contact`, this.contactForm);
        // ... rest of your code
      } catch (error) {
        // ... error handling
      }
    }
  }
};
</script>

<style scoped>
.site-footer {
  background: linear-gradient(to bottom, rgba(20, 20, 35, 0.8), rgba(10, 10, 25, 0.95));
  color: rgba(255, 255, 255, 0.8);
  padding: 80px 0 30px;
  margin-top: 80px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 50px;
}

.footer-section {
  flex: 1;
  min-width: 250px;
  margin-bottom: 30px;
  padding: 0 15px;
}

.brand-section {
  max-width: 350px;
}

.footer-logo {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.footer-logo-image {
  height: 32px;
  width: 32px;
  margin-right: 10px;
}

.footer-logo-text {
  font-size: 1.8rem;
  font-weight: 800;
  background: linear-gradient(135deg, #4a74dc 0%, #c33c65 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.footer-tagline {
  font-size: 0.95rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 20px;
}

.social-links {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

.social-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  color: white;
  transition: all 0.3s ease;
}

.social-link:hover {
  background: linear-gradient(135deg, #4a74dc 0%, #c33c65 100%);
  transform: translateY(-3px);
}

.footer-heading {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 20px;
  color: white;
  position: relative;
  padding-bottom: 10px;
}

.footer-heading::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 40px;
  height: 3px;
  background: linear-gradient(135deg, #4a74dc 0%, #c33c65 100%);
  border-radius: 10px;
}

.footer-links, .contact-info {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-links li {
  margin-bottom: 12px;
}

.footer-links a {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  transition: all 0.3s ease;
  font-size: 0.95rem;
}

.footer-links a:hover {
  color: white;
  padding-left: 5px;
}

.contact-info li {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.95rem;
}

.contact-info i {
  width: 20px;
  margin-right: 10px;
  color: rgba(255, 255, 255, 0.9);
}

.newsletter {
  margin-top: 25px;
}

.newsletter p {
  font-size: 0.95rem;
  margin-bottom: 10px;
}

.newsletter-form {
  display: flex;
}

.newsletter-input {
  flex: 1;
  padding: 10px 15px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px 0 0 4px;
  background-color: rgba(255, 255, 255, 0.05);
  color: white;
  font-size: 0.9rem;
}

.newsletter-input:focus {
  outline: none;
  border-color: rgba(255, 255, 255, 0.3);
  background-color: rgba(255, 255, 255, 0.08);
}

.newsletter-input.error {
  border-color: rgba(255, 77, 77, 0.5);
}

.newsletter-button {
  padding: 10px 15px;
  background: linear-gradient(135deg, #4a74dc 0%, #c33c65 100%);
  color: white;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.newsletter-button:hover {
  opacity: 0.9;
}

.newsletter-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.newsletter-error {
  color: #ff4d4d;
  font-size: 0.8rem;
  margin-top: 8px;
}

.newsletter-success {
  color: #4caf50;
  font-size: 0.8rem;
  margin-top: 8px;
}

.mini-spinner {
  width: 14px;
  height: 14px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s linear infinite;
  display: inline-block;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.footer-bottom {
  padding-top: 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
}

.copyright {
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 15px;
}

.legal-links {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.legal-links a {
  color: rgba(255, 255, 255, 0.6);
  text-decoration: none;
  transition: color 0.3s ease;
}

.legal-links a:hover {
  color: white;
}

@media (max-width: 992px) {
  .footer-main {
    flex-direction: column;
  }
  
  .footer-section {
    margin-bottom: 40px;
    padding: 0;
  }
  
  .brand-section {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .site-footer {
    padding: 50px 0 20px;
  }
  
  .footer-bottom {
    flex-direction: column;
    text-align: center;
  }
  
  .legal-links {
    justify-content: center;
    margin-top: 15px;
  }
}
</style> 