<template>
  <div class="modal-backdrop" v-if="isVisible" @click.self="close">
    <div class="modal-content user-guide-modal">
      <div class="modal-header">
        <h3>FaceSpy User Guide</h3>
        <button class="close-button" @click="close">&times;</button>
      </div>

      <div class="modal-body">
        <div class="user-guide-content">
          <div class="intro-section">
            <p class="intro-text">Welcome to the FaceSpy user guide. This guide will help you get the most out of our platform and navigate through all available features.</p>
          </div>

          <div class="guide-section">
            <h4><i class="fa fa-user-circle"></i> Account Management</h4>
            <div class="guide-content">
              <div class="guide-item">
                <h5>Signing In</h5>
                <p>Click the "Sign In" button in the top right corner to log in with your Google account. While not required for basic searches, signing in enables premium features and search history.</p>
              </div>
              <div class="guide-item">
                <h5>Managing Your Profile</h5>
                <p>Access your profile settings through the dropdown menu in the navigation bar after signing in. Here you can view your subscription status and manage account preferences.</p>
              </div>
            </div>
          </div>

          <div class="guide-section">
            <h4><i class="fa fa-upload"></i> Uploading Images</h4>
            <div class="guide-content">
              <div class="guide-item">
                <h5>Acceptable Image Types</h5>
                <p>FaceSpy accepts JPG, PNG, and WEBP image formats. For best results, use clear images with good lighting and minimal obstructions.</p>
              </div>
              <div class="guide-item">
                <h5>Upload Methods</h5>
                <ul>
                  <li>Drag and drop directly onto the upload area</li>
                  <li>Paste directly from clipboard (Ctrl+V or Cmd+V)</li>
                  <li>Click "Select File" to browse your device</li>
                </ul>
              </div>
              <div class="guide-item">
                <h5>Image Requirements</h5>
                <p>For optimal face detection:</p>
                <ul>
                  <li>Face should be clearly visible</li>
                  <li>Minimum recommended resolution: 300x300 pixels</li>
                  <li>Maximum file size: 5MB</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="guide-section">
            <h4><i class="fa fa-search"></i> Understanding Results</h4>
            <div class="guide-content">
              <div class="guide-item">
                <h5>Results Page Layout</h5>
                <p>Search results are presented in a grid format, sorted by match confidence. Each result shows:</p>
                <ul>
                  <li>Thumbnail image of the potential match</li>
                  <li>Match confidence percentage</li>
                  <li>Profile name (Premium users only)</li>
                </ul>
              </div>
              <div class="guide-item">
                <h5>Free vs. Premium Results</h5>
                <p>Free users see blurred previews and limited information. Premium users get full access to uncensored results, profile links, and additional creator information.</p>
              </div>
              <div class="guide-item">
                <h5>No Results Found</h5>
                <p>If no matches are found, try uploading a different image with a clearer view of the face, better lighting, or from a different angle.</p>
              </div>
            </div>
          </div>

          <div class="guide-section">
            <h4><i class="fa fa-star"></i> Premium Features</h4>
            <div class="guide-content">
              <div class="guide-item">
                <h5>Search Limits</h5>
                <p>Free users are limited to 10 searches per day. Premium users enjoy unlimited searches.</p>
              </div>
              <div class="guide-item">
                <h5>Accessing Premium Content</h5>
                <p>After upgrading to premium, all search results will automatically show uncensored with complete profile information and direct links.</p>
              </div>
              <div class="guide-item">
                <h5>Managing Your Subscription</h5>
                <p>You can manage your premium subscription from your account settings. This includes viewing your current plan, billing information, and cancellation options.</p>
              </div>
            </div>
          </div>

          <div class="guide-section">
            <h4><i class="fa fa-life-ring"></i> Troubleshooting</h4>
            <div class="guide-content">
              <div class="guide-item">
                <h5>Image Not Processing</h5>
                <p>If your image fails to process, check that:</p>
                <ul>
                  <li>The file is under 5MB</li>
                  <li>The format is supported (JPG, PNG, WEBP)</li>
                  <li>The image contains a clearly visible face</li>
                </ul>
              </div>
              <div class="guide-item">
                <h5>Search Limit Issues</h5>
                <p>The search counter resets at midnight UTC. If you believe there's an error with your search limit counter, try refreshing the page or clearing your browser cache.</p>
              </div>
              <div class="guide-item">
                <h5>Getting Support</h5>
                <p>For technical issues or questions, contact our support team at support@face-spy.com.</p>
              </div>
            </div>
          </div>
          
          <div class="premium-callout">
            <h4>Ready for Unlimited Access?</h4>
            <p>Upgrade to Premium for unlimited searches, uncensored results, and direct links to all profiles.</p>
            <button class="premium-button" @click="upgradeToPremium">Get Premium Now</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '../services/EventBus';
import ModalService from '../services/ModalService';
import { EVENTS } from '../services/ModalService';
import GoogleAuthService from '../services/GoogleAuthService';
import PremiumService from '../services/PremiumService';

export default {
  name: "UserGuideModal",
  data() {
    return {
      isVisible: false
    };
  },
  methods: {
    close() {
      this.isVisible = false;
      ModalService.hideUserGuide();
    },
    upgradeToPremium() {
      // Close the modal first
      this.close();
      
      // Check if user is logged in
      if (!GoogleAuthService.isLoggedIn()) {
        // console.log("User not logged in, redirecting to sign in");
        // Save intent to directly go to checkout after login
        localStorage.setItem('postLoginRedirect', '/premium/checkout');
        // Use the same sign-in method as the navbar but with a different source
        GoogleAuthService.signIn('user-guide-modal');
        return;
      }
      
      // If logged in, redirect to checkout directly
      PremiumService.redirectToCheckout();
    }
  },
  mounted() {
    // Listen for show/hide events
    EventBus.on(EVENTS.USER_GUIDE, (visible) => {
      // console.log("User Guide visibility changed:", visible);
      this.isVisible = visible;
    });
  },
  beforeUnmount() {
    // Clean up event listeners
    EventBus.off(EVENTS.USER_GUIDE);
  }
};
</script>

<style>
/* Specific styles for User Guide modal */
.user-guide-modal .modal-body {
  padding: 0;
}

.user-guide-content {
  padding: 20px;
  color: #666;
}

.intro-section {
  margin-bottom: 30px;
  text-align: center;
}

.intro-text {
  font-size: 1.1rem;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
}

.guide-section {
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 8px;
  background-color: #f9fafc;
}

.guide-section h4 {
  color: #333;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(74, 116, 220, 0.2);
}

.guide-section h4 i {
  color: #4a74dc;
  margin-right: 10px;
}

.guide-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.guide-item {
  margin-bottom: 15px;
}

.guide-item h5 {
  color: #333;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 1.1rem;
}

.guide-item p {
  margin-top: 0;
  line-height: 1.6;
}

.guide-item ul {
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 20px;
}

.guide-item li {
  margin-bottom: 5px;
  line-height: 1.4;
}

/* Premium callout styles */
.premium-callout {
  margin: 40px 0;
  padding: 25px;
  border-radius: 8px;
  background: linear-gradient(135deg, rgba(74, 116, 220, 0.1) 0%, rgba(195, 60, 101, 0.1) 100%);
  text-align: center;
  border: 1px solid rgba(74, 116, 220, 0.2);
}

.premium-callout h4 {
  color: #333;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 1.4rem;
}

.premium-button {
  display: inline-block;
  margin-top: 15px;
  padding: 10px 25px;
  background: linear-gradient(135deg, #4a74dc 0%, #c33c65 100%);
  color: white;
  border-radius: 25px;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
  border: none;
  cursor: pointer;
}

.premium-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .guide-section {
    padding: 15px;
  }
  
  .guide-section h4 {
    font-size: 1.2rem;
  }
  
  .guide-item h5 {
    font-size: 1rem;
  }
}
</style> 