const SEARCH_LIMIT_KEY = 'search_limit_data';
const DAILY_LIMIT = 10;

class SearchLimitService {
  // Get the current search limit data from localStorage
  getLimitData() {
    const storedData = localStorage.getItem(SEARCH_LIMIT_KEY);
    // console.log("Raw stored search limit data:", storedData);
    
    if (!storedData) {
      // console.log("No stored search limit data found, returning default");
      return {
        count: 0,
        date: new Date().toDateString()
      };
    }
    
    try {
      const parsedData = JSON.parse(storedData);
      // console.log("Parsed search limit data:", parsedData);
      return parsedData;
    } catch (e) {
      // console.error('Error parsing search limit data', e);
      return {
        count: 0,
        date: new Date().toDateString()
      };
    }
  }
  
  // Save search limit data to localStorage
  saveLimitData(data) {
    // console.log("Saving search limit data:", data);
    localStorage.setItem(SEARCH_LIMIT_KEY, JSON.stringify(data));
  }
  
  // Increment the search count and return updated count
  incrementSearchCount() {
    const data = this.getLimitData();
    const today = new Date().toDateString();
    // console.log("Current date:", today, "Stored date:", data.date);
    
    // Reset count if it's a new day
    if (data.date !== today) {
      // console.log("New day detected, resetting count");
      data.count = 0;
      data.date = today;
    }
    
    // Increment count
    data.count += 1;
    // console.log("Incrementing count to:", data.count);
    
    // Save updated data
    this.saveLimitData(data);
    
    return data.count;
  }
  
  // Get current search count
  getCurrentCount() {
    const data = this.getLimitData();
    const today = new Date().toDateString();
    
    // Reset count if it's a new day
    if (data.date !== today) {
      data.count = 0;
      data.date = today;
      this.saveLimitData(data);
    }
    
    return data.count;
  }
  
  // Check if user has reached daily limit
  hasReachedLimit() {
    return this.getCurrentCount() >= DAILY_LIMIT;
  }
  
  // Get remaining searches
  getRemainingSearches() {
    return Math.max(0, DAILY_LIMIT - this.getCurrentCount());
  }
  
  // Get daily limit
  getDailyLimit() {
    return DAILY_LIMIT;
  }
}

export default new SearchLimitService(); 