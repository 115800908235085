import axios from "axios";
import { API_URL, GOOGLE_CLIENT_ID } from '../config';

// To avoid circular imports, we'll get AuthStore lazily
let authStore = null;
const getAuthStore = () => {
  if (!authStore) {
    authStore = require("./AuthStore").default;
  }
  return authStore;
};

class GoogleAuthService {
  constructor() {
    this.gapiLoaded = false;
    this.gsiLoaded = false;
    this.tokenClient = null;
    this.initPromise = null;
    this.idTokenPromise = null;
  }

  async loadGapi() {
    if (this.gapiLoaded) return Promise.resolve();

    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.async = true;
      script.defer = true;
      script.onload = () => {
        this.gapiLoaded = true;
        resolve();
      };
      script.onerror = (error) => {
        reject(new Error("Failed to load Google Identity Services script"));
      };
      document.head.appendChild(script);
    });
  }

  async initGoogleAuth() {
    if (this.initPromise) return this.initPromise;

    this.initPromise = new Promise((resolve, reject) => {
      // Load Google Identity Services client
      this.loadGapi()
        .then(() => {
          try {
            // Initialize Google sign-in
            if (
              typeof window.google !== "undefined" &&
              window.google.accounts
            ) {
              // Initialize Google Identity Services
              window.google.accounts.id.initialize({
                client_id: GOOGLE_CLIENT_ID,
                callback: (credentialResponse) => {
                  // console.log("Google sign-in callback received");
                  // Resolve any pending promise with the credential
                  if (this.idTokenPromise) {
                    const resolver = this.idTokenPromise.resolver;
                    this.idTokenPromise = null;
                    if (resolver) resolver(credentialResponse);
                  }
                },
                auto_select: false,
                cancel_on_tap_outside: true,
              });

              resolve();
            } else {
              throw new Error("Google Identity Services not loaded properly");
            }
          } catch (error) {
            // console.error("Google Sign-In initialization failed:", error);
            reject(error);
          }
        })
        .catch((error) => {
          // console.error("Failed to initialize Google Auth:", error);
          reject(error);
        });
    });

    return this.initPromise;
  }

  async signIn(source = "navbar") {
    await this.initGoogleAuth();

    // Create a promise that will be resolved when the user completes the sign-in
    const credentialPromise = new Promise((resolve, reject) => {
      // Store the resolver function to be called by the callback
      this.idTokenPromise = { resolver: resolve };

      // Show Google One Tap prompt
      if (typeof window.google !== "undefined" && window.google.accounts) {
        window.google.accounts.id.prompt((notification) => {
          if (
            notification.isNotDisplayed() ||
            notification.isSkippedMoment()
          ) {
            console.warn(
              "Google One Tap prompt not displayed or skipped:",
              notification
            );
            // If One Tap doesn't work, try rendering a button
            try {
              // Render a button as fallback
              window.google.accounts.id.renderButton(
                document.getElementById("googleButtonContainer") ||
                  document.createElement("div"),
                {
                  theme: "outline",
                  size: "large",
                  width: 240,
                }
              );
            } catch (err) {
              // console.error("Failed to render button:", err);
            }
          } else {
            // console.log("Google One Tap prompt displayed successfully");
          }
        });

        // Set a timeout to reject the promise if no response after 60 seconds
        setTimeout(() => {
          if (this.idTokenPromise) {
            this.idTokenPromise = null;
            reject(new Error("Authentication timed out. Please try again."));
          }
        }, 60000);
      } else {
        reject(new Error("Google Identity Services not loaded properly"));
      }
    });

    // Wait for credential from One Tap
    const credential = await credentialPromise;

    if (!credential || !credential.credential) {
      throw new Error("Failed to get valid Google credentials");
    }

    return this.processGoogleCredential(credential.credential, source);
  }

  async processGoogleCredential(credential, source = "navbar") {
    // Send token to backend
    const response = await axios.post(`${API_URL}/auth/google`, {
      idToken: credential,
    });

    const { accessToken, refreshToken, expiresIn, user } = response.data;

    // Update AuthStore
    const AuthStore = getAuthStore();
    AuthStore.setUser(user);
    AuthStore.setTokens({
      accessToken: accessToken,
      refreshToken: refreshToken,
      expiresAt: Date.now() + expiresIn * 1000,
    });
    AuthStore.setLoginSource(source);

    // Check if there's a saved redirect path
    const postLoginRedirect = localStorage.getItem("postLoginRedirect");
    if (postLoginRedirect) {
      // Clear the redirect path first to avoid loops
      localStorage.removeItem("postLoginRedirect");
      
      // Handle special paths
      if (postLoginRedirect === "/premium/checkout") {
        // Wait a moment before redirecting to ensure auth state is fully updated
        setTimeout(() => {
          // Dynamic import to avoid circular dependency
          const PremiumService = require("./PremiumService").default;
          PremiumService.redirectToCheckout();
        }, 300);
      } else {
        // For normal redirects, navigate to the specified path
        setTimeout(() => {
          window.location.href = postLoginRedirect;
        }, 300);
      }
    }

    return user;
  }

  signOut() {
    if (typeof window.google !== "undefined" && window.google.accounts) {
      window.google.accounts.id.disableAutoSelect();
    }

    // Clear authentication data using AuthStore
    const AuthStore = getAuthStore();

    // Call backend logout if refresh token exists
    const refreshToken = localStorage.getItem("refreshToken");
    const accessToken = AuthStore.tokens.accessToken;

    if (refreshToken) {
      axios
        .post(
          `${API_URL}/auth/logout`,
          { refreshToken },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .catch((error) => {
          console.warn("Logout API call failed:", error.message);
          // Continue with local logout regardless of API response
        })
        .finally(() => {
          AuthStore.clearAuth();
          // Refresh the page to update UI state
          setTimeout(() => {
            window.location.reload();
          }, 300);
        });
      return;
    }

    AuthStore.clearAuth();
    // Refresh the page to update UI state even if there was no refresh token
    setTimeout(() => {
      window.location.reload();
    }, 300);
  }

  isLoggedIn() {
    const AuthStore = getAuthStore();
    return AuthStore.isLoggedIn();
  }

  // Add isAuthenticated as an alias to isLoggedIn for compatibility
  isAuthenticated() {
    return this.isLoggedIn();
  }

  async refreshToken() {
    const refreshToken = localStorage.getItem("refreshToken");
    if (!refreshToken) return false;

    try {
      const response = await axios.post(`${API_URL}/auth/refresh`, {
        refreshToken: refreshToken,
      });

      const { accessToken, expiresIn } = response.data;

      // Update auth store with new token
      const AuthStore = getAuthStore();
      AuthStore.setTokens({
        accessToken: accessToken,
        refreshToken: refreshToken,
        expiresAt: Date.now() + expiresIn * 1000,
      });

      return true;
    } catch (error) {
      console.error("Token refresh failed:", error);
      // If refresh fails, sign out the user
      this.signOut();
      return false;
    }
  }

  getUser() {
    const AuthStore = getAuthStore();
    return AuthStore.user;
  }

  getUserId() {
    const user = this.getUser();
    return user ? user.id : null;
  }

  getAccessToken() {
    const AuthStore = getAuthStore();
    return AuthStore.tokens.accessToken;
  }

  getLoginSource() {
    const AuthStore = getAuthStore();
    return AuthStore.loginSource;
  }
}

export default new GoogleAuthService();
