<template>
  <div id="app">
    <Navbar />
    <div>
      <router-view />
    </div>
    <Footer />
    
    <!-- Modals -->
    <PrivacyPolicyModal />
    <TermsOfServiceModal />
    <CookiePolicyModal />
    <DmcaModal />
    <HowItWorksModal />
    <UserGuideModal />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import PrivacyPolicyModal from "@/components/PrivacyPolicyModal.vue";
import TermsOfServiceModal from "@/components/TermsOfServiceModal.vue";
import CookiePolicyModal from "@/components/CookiePolicyModal.vue";
import DmcaModal from "@/components/DmcaModal.vue";
import HowItWorksModal from "@/components/HowItWorksModal.vue";
import UserGuideModal from "@/components/UserGuideModal.vue";
import GoogleAuthService from "@/services/GoogleAuthService";
import PremiumService from "@/services/PremiumService";

export default defineComponent({
  name: "App",
  components: {
    Navbar,
    Footer,
    PrivacyPolicyModal,
    TermsOfServiceModal,
    CookiePolicyModal,
    DmcaModal,
    HowItWorksModal,
    UserGuideModal
  },
  data() {
    return {
      metaTags: {
        home: {
          title: "FaceSpy - OnlyFans Search by Face",
          description: "Find similar OnlyFans profiles and discover content creators using AI-powered facial recognition technology.",
          keywords: "OnlyFans search, OnlyFans face search, find OnlyFans creators"
        },
        search: {
          title: "Search OnlyFans Profiles by Face | FaceSpy",
          description: "Upload a photo and find similar OnlyFans creators using our advanced AI facial recognition technology.",
          keywords: "OnlyFans face search, find similar OnlyFans, OnlyFans facial recognition"
        },
        results: {
          title: "Similar OnlyFans Profiles Found | FaceSpy",
          description: "Discover OnlyFans content creators that match your search criteria using AI-powered facial recognition.",
          keywords: "similar OnlyFans profiles, OnlyFans matches, OnlyFans AI search results"
        }
      }
    };
  },
  methods: {
    updateMetaTags(route) {
      const routeName = route.name || 'home';
      const meta = this.metaTags[routeName] || this.metaTags.home;

      // Update document title
      document.title = meta.title;

      // Update meta description
      let descriptionTag = document.querySelector('meta[name="description"]');
      if (descriptionTag) {
        descriptionTag.setAttribute('content', meta.description);
      }

      // Update meta keywords
      let keywordsTag = document.querySelector('meta[name="keywords"]');
      if (keywordsTag) {
        keywordsTag.setAttribute('content', meta.keywords);
      }

      // Update Open Graph tags
      let ogTitleTag = document.querySelector('meta[property="og:title"]');
      let ogDescTag = document.querySelector('meta[property="og:description"]');
      if (ogTitleTag) ogTitleTag.setAttribute('content', meta.title);
      if (ogDescTag) ogDescTag.setAttribute('content', meta.description);

      // Update Twitter Card tags
      let twitterTitleTag = document.querySelector('meta[name="twitter:title"]');
      let twitterDescTag = document.querySelector('meta[name="twitter:description"]');
      if (twitterTitleTag) twitterTitleTag.setAttribute('content', meta.title);
      if (twitterDescTag) twitterDescTag.setAttribute('content', meta.description);
    },
    async initGoogleAuth() {
      try {
        // Use our GoogleAuthService for initialization
        await GoogleAuthService.initGoogleAuth();
        // console.log("Google Auth initialized from App.vue");
      } catch (error) {
        console.error("Failed to initialize Google Auth from App.vue:", error);
      }
    },

    async initPremiumStatus() {
      if (GoogleAuthService.isLoggedIn()) {
        await PremiumService.loadPremiumStatus();
      }
    },

    async handleStripeRedirect() {
      // Check if this is a redirect from Stripe
      const urlParams = new URLSearchParams(window.location.search);
      const sessionId = urlParams.get("session_id");

      if (sessionId && this.$route.path === "/payment/success") {
        // This is a redirect from Stripe checkout to our success page
        try {
          const success = await PremiumService.handleSuccessfulPayment(
            sessionId
          );
          if (!success) {
            console.error("Failed to verify payment");
          }
        } catch (error) {
          console.error("Failed to handle Stripe redirect:", error);
        }
      } else if (this.$route.path === "/payment/cancel") {
        // This is a redirect from Stripe checkout to our cancel page
        // console.log("Payment was cancelled by the user");
      }
    },
  },
  watch: {
    '$route'(to) {
      this.updateMetaTags(to);
    }
  },
  async created() {
    // Initialize Google Auth
    await this.initGoogleAuth();

    // Initialize Premium Status
    await this.initPremiumStatus();

    // Handle Stripe redirect if applicable
    await this.handleStripeRedirect();

    // Set initial meta tags
    this.updateMetaTags(this.$route);
  },
  mounted() {
    // Check if this is a redirect from Stripe
    this.handleStripeRedirect();
  },
});
</script>
<style>
/*nav {*/
/*  text-align: center;*/
/*  padding: 30px;*/
/*  font-size: 24px;*/
/*}*/
</style>
