<template>
  <div class="modal-backdrop" v-if="isVisible" @click.self="close">
    <div class="modal-content privacy-policy-modal">
      <div class="modal-header">
        <h3>Privacy Policy</h3>
        <button class="close-button" @click="close">&times;</button>
      </div>

      <div class="modal-body">
        <div class="privacy-content">
          <h4>1. Introduction</h4>
          <p>Welcome to FaceSpy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our service.</p>
          
          <h4>2. Information We Collect</h4>
          <p>We collect information that you provide directly to us, including:</p>
          <ul>
            <li>Account information (when you sign up)</li>
            <li>Images you upload for processing</li>
            <li>Payment information (for premium subscriptions)</li>
            <li>Usage data and search history</li>
          </ul>
          
          <h4>3. How We Use Your Information</h4>
          <p>We use the information we collect to:</p>
          <ul>
            <li>Provide, maintain, and improve our services</li>
            <li>Process your search requests and display results</li>
            <li>Process transactions and send related information</li>
            <li>Monitor and analyze usage patterns and trends</li>
            <li>Detect, prevent, and address technical issues</li>
          </ul>
          
          <h4>4. Data Retention</h4>
          <p>We retain uploaded images for a limited time necessary to process your search. Uploaded images are automatically deleted after search completion. Account information is retained while your account is active.</p>
          
          <h4>5. Security</h4>
          <p>We implement appropriate security measures to protect your personal information. However, no method of transmission over the internet is 100% secure.</p>
          
          <h4>6. Your Rights</h4>
          <p>Depending on your location, you may have rights regarding your personal information, including:</p>
          <ul>
            <li>Access to your personal information</li>
            <li>Correction of inaccurate data</li>
            <li>Deletion of your data</li>
            <li>Restriction of processing</li>
            <li>Data portability</li>
          </ul>
          
          <h4>7. Cookies</h4>
          <p>We use cookies and similar tracking technologies to track activity on our service and hold certain information.</p>
          
          <h4>8. Third-Party Services</h4>
          <p>Our service integrates with third-party services (like Google for authentication and Stripe for payments) that may collect information about you.</p>
          
          <h4>9. Changes to This Privacy Policy</h4>
          <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
          
          <h4>10. Contact Us</h4>
          <p>If you have any questions about this Privacy Policy, please contact us at support@face-spy.com.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '../services/EventBus';
import ModalService from '../services/ModalService';
import { EVENTS } from '../services/ModalService';

export default {
  name: "PrivacyPolicyModal",
  data() {
    return {
      isVisible: false
    };
  },
  methods: {
    close() {
      this.isVisible = false;
      ModalService.hidePrivacyPolicy();
    }
  },
  mounted() {
    // Listen for show/hide events
    EventBus.on(EVENTS.PRIVACY_POLICY, (visible) => {
      // console.log("Privacy Policy visibility changed:", visible);
      this.isVisible = visible;
    });
  },
  beforeUnmount() {
    // Clean up event listeners
    EventBus.off(EVENTS.PRIVACY_POLICY);
  }
};
</script>

<style>
/* Using non-scoped styles to ensure they have higher specificity */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.modal-content {
  background-color: white;
  border-radius: 8px;
  width: 100%;
  max-width: 800px;
  max-height: 90vh;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  position: relative;
  z-index: 10000;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  border-bottom: 1px solid #eee;
  position: sticky;
  top: 0;
  background: white;
  z-index: 10001;
}

.modal-header h3 {
  margin: 0;
  font-size: 1.5rem;
  color: #333;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
}

.modal-body {
  padding: 0;
  max-height: calc(90vh - 60px);
  overflow-y: auto;
}

.privacy-content {
  padding: 20px;
}

.privacy-content h4 {
  color: #333;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 1.2rem;
}

.privacy-content p, .privacy-content li {
  color: #666;
  line-height: 1.6;
  margin-bottom: 10px;
}

.privacy-content ul {
  padding-left: 20px;
  margin-bottom: 15px;
}
</style> 