<template>
  <div class="modal-backdrop" v-if="isVisible" @click.self="close">
    <div class="modal-content tos-modal">
      <div class="modal-header">
        <h3>Terms of Service</h3>
        <button class="close-button" @click="close">&times;</button>
      </div>

      <div class="modal-body">
        <div class="tos-content">
          <h4>1. Acceptance of Terms</h4>
          <p>By accessing or using FaceSpy, you agree to be bound by these Terms of Service and all applicable laws and regulations. If you do not agree with any of these terms, you are prohibited from using or accessing this site.</p>
          
          <h4>2. Use License</h4>
          <p>Permission is granted to temporarily use FaceSpy for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:</p>
          <ul>
            <li>Modify or copy the materials</li>
            <li>Use the materials for any commercial purpose</li>
            <li>Remove any copyright or other proprietary notations from the materials</li>
            <li>Transfer the materials to another person or "mirror" the materials on any other server</li>
          </ul>
          
          <h4>3. Account Registration and Security</h4>
          <p>You may need to create an account to use certain features of FaceSpy. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</p>
          
          <h4>4. Subscription and Payments</h4>
          <p>Premium services require a subscription. By purchasing a premium subscription, you agree to the recurring payment terms specified at the time of purchase. You can cancel your subscription at any time through your account settings.</p>
          
          <h4>5. Face Recognition Use</h4>
          <p>Our face recognition service is designed to help identify OnlyFans profiles that match your uploads. By using our service, you represent that you have legitimate reasons for the search and will not use the results for harassment, stalking, or any illegal purpose.</p>
          
          <h4>6. User Content</h4>
          <p>By uploading images to our service, you:</p>
          <ul>
            <li>Grant us the right to process and analyze these images for the purpose of providing our service</li>
            <li>Represent that you have the right to upload and use these images</li>
            <li>Understand that we do not claim ownership of your uploaded content</li>
          </ul>
          
          <h4>7. Limitations and Disclaimers</h4>
          <p>FaceSpy and its materials are provided on an 'as is' basis. FaceSpy makes no warranties, expressed or implied, and hereby disclaims all warranties including, without limitation, implied warranties of merchantability, fitness for a particular purpose, or non-infringement.</p>
          
          <h4>8. Limitations of Liability</h4>
          <p>FaceSpy shall not be liable for any direct, indirect, incidental, special, or consequential damages that result from the use of, or the inability to use, the service or materials on the site, even if we have been advised of the possibility of such damages.</p>
          
          <h4>9. Termination</h4>
          <p>We may terminate or suspend your account and access to our service immediately, without prior notice, for conduct that we believe violates these Terms or is harmful to other users, us, or third parties, or for any other reason at our sole discretion.</p>
          
          <h4>10. Governing Law</h4>
          <p>These Terms shall be governed by and construed in accordance with the laws of the jurisdiction in which FaceSpy operates, without regard to its conflict of law provisions.</p>
          
          <h4>11. Changes to Terms</h4>
          <p>FaceSpy reserves the right to modify these terms at any time. We will notify users of any material changes by posting the new Terms on the site.</p>
          
          <h4>12. Contact Us</h4>
          <p>If you have any questions about these Terms, please contact us at support@face-spy.com.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '../services/EventBus';
import ModalService from '../services/ModalService';
import { EVENTS } from '../services/ModalService';

export default {
  name: "TermsOfServiceModal",
  data() {
    return {
      isVisible: false
    };
  },
  methods: {
    close() {
      this.isVisible = false;
      ModalService.hideTermsOfService();
    }
  },
  mounted() {
    // Listen for show/hide events
    EventBus.on(EVENTS.TERMS_OF_SERVICE, (visible) => {
      // console.log("Terms of Service visibility changed:", visible);
      this.isVisible = visible;
    });
  },
  beforeUnmount() {
    // Clean up event listeners
    EventBus.off(EVENTS.TERMS_OF_SERVICE);
  }
};
</script>

<style>
/* Adding specific styles for the Terms of Service modal */
.tos-content h4 {
  color: #333;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 1.2rem;
}

.tos-content p, 
.tos-content li {
  color: #666;
  line-height: 1.6;
  margin-bottom: 10px;
}

.tos-content ul {
  padding-left: 20px;
  margin-bottom: 15px;
}
</style> 