<template>
  <div class="modal-backdrop" v-if="isVisible" @click.self="close">
    <div class="modal-content cookie-policy-modal">
      <div class="modal-header">
        <h3>Cookie Policy</h3>
        <button class="close-button" @click="close">&times;</button>
      </div>

      <div class="modal-body">
        <div class="cookie-policy-content">
          <h4>1. Introduction</h4>
          <p>This Cookie Policy explains how FaceSpy ("we", "us", or "our") uses cookies and similar technologies on our website. By using our website, you consent to the use of cookies as described in this policy.</p>
          
          <h4>2. What Are Cookies</h4>
          <p>Cookies are small text files that are stored on your device when you visit a website. They are widely used to make websites work more efficiently and provide information to the website owners.</p>
          
          <h4>3. Types of Cookies We Use</h4>
          <p>We use the following types of cookies:</p>
          <ul>
            <li><strong>Essential cookies:</strong> These cookies are necessary for the website to function properly and cannot be disabled in our systems.</li>
            <li><strong>Performance cookies:</strong> These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site.</li>
            <li><strong>Functionality cookies:</strong> These cookies enable the website to provide enhanced functionality and personalization.</li>
            <li><strong>Targeting cookies:</strong> These cookies may be set through our site by our advertising partners to build a profile of your interests.</li>
          </ul>
          
          <h4>4. How We Use Cookies</h4>
          <p>We use cookies for various purposes, including:</p>
          <ul>
            <li>Authentication and security (maintaining your login session)</li>
            <li>Remembering your preferences and settings</li>
            <li>Analyzing how our website is used so we can improve it</li>
            <li>Tracking your search usage and daily limits</li>
            <li>Personalizing your experience</li>
          </ul>
          
          <h4>5. Third-Party Cookies</h4>
          <p>Some cookies are placed by third parties on our website:</p>
          <ul>
            <li><strong>Google:</strong> We use Google services for authentication and analytics</li>
            <li><strong>Stripe:</strong> For payment processing</li>
            <li><strong>Analytics partners:</strong> To help us understand how visitors use our site</li>
          </ul>
          
          <h4>6. Managing Cookies</h4>
          <p>Most web browsers allow you to control cookies through their settings. You can:</p>
          <ul>
            <li>Delete existing cookies</li>
            <li>Block cookies entirely or selectively</li>
            <li>Configure browser settings to notify you when cookies are being set</li>
          </ul>
          <p>Please note that restricting cookies may impact your experience on our website and limit certain functionality.</p>
          
          <h4>7. Local Storage</h4>
          <p>In addition to cookies, we use local storage to store certain preferences and data, including your search usage limits. Local storage is similar to cookies but can store larger amounts of data.</p>
          
          <h4>8. Changes to This Cookie Policy</h4>
          <p>We may update our Cookie Policy from time to time. We will notify you of any changes by posting the new Cookie Policy on this page.</p>
          
          <h4>9. Contact Us</h4>
          <p>If you have any questions about our Cookie Policy, please contact us at support@face-spy.com.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '../services/EventBus';
import ModalService from '../services/ModalService';
import { EVENTS } from '../services/ModalService';

export default {
  name: "CookiePolicyModal",
  data() {
    return {
      isVisible: false
    };
  },
  methods: {
    close() {
      this.isVisible = false;
      ModalService.hideCookiePolicy();
    }
  },
  mounted() {
    // Listen for show/hide events
    EventBus.on(EVENTS.COOKIE_POLICY, (visible) => {
      // console.log("Cookie Policy visibility changed:", visible);
      this.isVisible = visible;
    });
  },
  beforeUnmount() {
    // Clean up event listeners
    EventBus.off(EVENTS.COOKIE_POLICY);
  }
};
</script>

<style>
/* Specific styles for Cookie Policy modal */
.cookie-policy-content h4 {
  color: #333;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 1.2rem;
}

.cookie-policy-content p, 
.cookie-policy-content li {
  color: #666;
  line-height: 1.6;
  margin-bottom: 10px;
}

.cookie-policy-content ul {
  padding-left: 20px;
  margin-bottom: 15px;
}

.cookie-policy-content strong {
  font-weight: 600;
  color: #555;
}
</style> 