import { createWebHistory, createRouter } from "vue-router";
import Settings from "@/components/Settings.vue";

export default function getRouter(home) {
  const routes = [
    {
      path: `/`,
      alias: "/home",
      name: "home",
      component: () => import(`./components/Home${home}.vue`),
    },
    {
      path: "/results",
      name: "results",
      component: () => import(`./components/Results.vue`),
      props: true,
    },
    {
      path: "/index_pbp.html",
      name: "index_pbp",
      component: () => import(`./components/HomePBP.vue`),
    },
    {
      path: "/payment/success",
      name: "payment-success",
      component: () => import(`./components/PaymentSuccess.vue`),
    },
    {
      path: "/payment/cancel",
      name: "payment-cancel",
      component: () => import(`./components/PaymentCancel.vue`),
    },
    {
      path: "/settings",
      name: "settings",
      component: Settings,
      meta: { requiresAuth: true }
    },
  ];
  return createRouter({
    history: createWebHistory(),
    routes: routes,
  });
}
