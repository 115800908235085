<template>
  <nav class="navbar">
    <div class="navbar-container">
      <div class="navbar-logo">
        <router-link to="/" class="logo-link">
          <img src="@/assets/logo.svg" alt="FaceSpy Logo" class="logo-image" />
          <span class="logo-text">FaceSpy</span>
        </router-link>
      </div>

      <div class="auth-section">
        <div v-if="isLoggedIn" class="user-profile">
          <!-- Loading state for premium status -->
          <div v-if="isStatusLoading || isVerifyingPayment" class="premium-loading">
            <div class="mini-spinner"></div>
            <span v-if="isVerifyingPayment" class="verifying-text">Verifying payment...</span>
          </div>

          <!-- Reactivate button for canceled premium users that are still in premium period -->
          <button
            v-else-if="isSubscriptionCancelled && premiumUntil && new Date(premiumUntil) > new Date()"
            class="upgrade-button reactivate-button"
            @click="reactivateSubscription"
          >
            <i class="fa fa-refresh"></i> Reactivate Subscription
          </button>

          <!-- Upgrade button for non-premium users -->
          <button
            v-else-if="!isPremium"
            class="upgrade-button"
            @click="upgrade"
          >
            <span class="search-limit-badge">{{ searchCount }}/{{ searchLimit }}</span>
            <i class="fa fa-star"></i> Upgrade
          </button>

          <!-- Premium badge for premium users -->
          <div v-else class="premium-badge">
            <i class="fa fa-star"></i> Premium
          </div>

          <div class="profile-info" @click="toggleProfileMenu">
            <img 
              :src="getProfileImageUrl()" 
              alt="Profile" 
              class="profile-picture" 
              @error="handleImageError"
            />
            <span class="user-name">{{ user.name }}</span>
            <i class="fa fa-caret-down"></i>
          </div>
          <div class="profile-menu" v-if="showProfileMenu">
            <div class="menu-item" @click="goToSettings">
              <i class="fa fa-cog"></i> Settings
            </div>
            <div class="menu-item" @click="logout">
              <i class="fa fa-sign-out"></i> Logout
            </div>
          </div>
        </div>
        <div v-else class="guest-controls">
          <div class="search-limit-display" v-if="searchCount > 0">
            <span class="search-limit-badge">{{ searchCount }}/{{ searchLimit }}</span>
          </div>
          <button
            class="login-button"
            @click="signInWithGoogle"
            :disabled="isLoading"
          >
            <div v-if="isLoading" class="loading-spinner"></div>
            <i v-else class="fa fa-google"></i>
            <span>{{ isLoading ? "Signing in..." : "Sign in" }}</span>
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import GoogleAuthService from "@/services/GoogleAuthService";
import PremiumService from "@/services/PremiumService";
import EventBus from "@/services/EventBus";
import SearchLimitService from "@/services/SearchLimitService";

export default {
  name: "Navbar",
  data() {
    return {
      showProfileMenu: false,
      isLoading: false,
      isPremium: false,
      premiumUntil: null,
      isSubscriptionCancelled: false,
      checkoutUrl: null,
      isStatusLoading: true,
      isVerifyingPayment: false,
      searchCount: 0,
      searchLimit: 10,
      isReactivating: false,
    };
  },
  computed: {
    isLoggedIn() {
      return GoogleAuthService.isAuthenticated();
    },
    user() {
      return GoogleAuthService.getUser() || { name: "User", photoUrl: "" };
    },
  },
  methods: {
    toggleProfileMenu() {
      this.showProfileMenu = !this.showProfileMenu;
    },
    getProfileImageUrl() {
      let url = '';
      
      // Use the user's photo URL if available, or generate a placeholder
      if (this.user.photoUrl && this.user.photoUrl.trim() !== '') {
        url = this.user.photoUrl;
      } else {
        // Generate a user avatar with first initial and full name
        const name = this.user.name || '?';
        url = `https://ui-avatars.com/api/?name=${encodeURIComponent(name)}&background=4A74DC&color=fff&size=100`;
      }
      
      return url;
    },
    handleImageError(event) {
      // Generate a user avatar with first initial and full name
      const name = this.user.name || '?';
      event.target.src = `https://ui-avatars.com/api/?name=${encodeURIComponent(name)}&background=4A74DC&color=fff&size=100`;
      
      // Remove the error handler to prevent potential loops
      event.target.removeEventListener('error', this.handleImageError);
    },
    async signInWithGoogle() {
      if (this.isLoading) return;
      
      this.isLoading = true;
      try {
        await GoogleAuthService.signIn('navbar');
        // Explicitly refresh the page after successful login
        setTimeout(() => {
          window.location.reload();
        }, 300);
      } catch (error) {
        console.error('Authentication failed:', error);
        alert('Sign in failed. Please try again.');
      } finally {
        this.isLoading = false;
      }
    },
    async logout() {
      await GoogleAuthService.signOut();
      this.showProfileMenu = false;
      this.$router.push('/');
    },
    goToSettings() {
      this.showProfileMenu = false;
      this.$router.push('/settings');
    },
    formatPremiumDate(date) {
      return PremiumService.formatPremiumDate(date);
    },
    async loadPremiumStatus() {
      if (this.isLoggedIn) {
        this.isStatusLoading = true;
        await PremiumService.loadPremiumStatus();
        this.isPremium = PremiumService.isPremiumUser();
        this.isSubscriptionCancelled = PremiumService.isSubscriptionCancelled();
        this.premiumUntil = PremiumService.getPremiumUntil();
        this.checkoutUrl = PremiumService.getCheckoutUrl();
        
        this.isStatusLoading = false;
      } else {
        this.isStatusLoading = false;
      }
      
      // Always load search limit, regardless of login status
      this.loadSearchLimit();
    },
    upgrade() {
      PremiumService.redirectToCheckout();
    },
    async reactivateSubscription() {
      if (this.isReactivating) return;
      
      this.isReactivating = true;
      try {
        const success = await PremiumService.reactivateSubscription();
        if (success) {
          // Reload premium status to update UI
          await this.loadPremiumStatus();
        } else {
          console.error("Failed to reactivate subscription");
        }
      } catch (error) {
        console.error("Error reactivating subscription:", error);
      } finally {
        this.isReactivating = false;
      }
    },
    setupEventListeners() {
      // Listen for premium verification state changes
      EventBus.on("premium-verification-change", (isVerifying) => {
        this.isVerifyingPayment = isVerifying;
      });
      
      // Listen for premium status changes
      EventBus.on("premium-status-change", async (isPremium) => {
        this.isPremium = isPremium;
        this.isSubscriptionCancelled = PremiumService.isSubscriptionCancelled();
        this.premiumUntil = PremiumService.getPremiumUntil();
        
        // Load search limit data after premium status changes
        this.loadSearchLimit();
      });

      // Listen for subscription cancellation
      EventBus.on("premium-cancelled", (premiumUntil) => {
        this.isSubscriptionCancelled = true;
        this.premiumUntil = premiumUntil;
        // Don't set isPremium to false here, as they still have access until premiumUntil
      });
      
      // Listen for search count updates
      EventBus.on('search-count-updated', () => {
        this.loadSearchLimit();
      });
    },
    loadSearchLimit() {
      // Always update search limits for all users regardless of login or premium status
      this.searchCount = SearchLimitService.getCurrentCount();
      this.searchLimit = SearchLimitService.getDailyLimit();
    },
  },
  async mounted() {
    // Close profile menu when clicking outside
    document.addEventListener('click', (e) => {
      if (this.showProfileMenu && !e.target.closest('.profile-info') && !e.target.closest('.profile-menu')) {
        this.showProfileMenu = false;
      }
    });
    
    // Set up event listeners for premium status
    this.setupEventListeners();
    
    // Check if already in verification mode
    this.isVerifyingPayment = PremiumService.isInVerificationMode();
    
    // Use the EventBus to listen for authentication events
    EventBus.on('user-authenticated', () => {
      this.showProfileMenu = false;
      this.loadPremiumStatus();
    });
    
    // Listen for search count updates
    EventBus.on('search-count-updated', () => {
      this.loadSearchLimit();
    });
    
    // Load premium status on mount
    await this.loadPremiumStatus();
  },
  beforeUnmount() {
    // Clean up event listeners
    EventBus.off('user-authenticated');
    EventBus.off('premium-verification-change');
    EventBus.off('premium-status-change');
    EventBus.off('premium-cancelled');
    EventBus.off('search-count-updated');
  },
};
</script>

<style scoped>
.navbar {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  position: sticky;
  top: 0;
  z-index: 100;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.6rem 1.5rem;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}

.navbar-logo {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
}

.logo-link {
  text-decoration: none;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 8px;
}

.logo-image {
  height: 26px;
  width: 26px;
  object-fit: contain;
  transition: transform 0.3s ease;
}

.logo-link:hover .logo-image {
  transform: rotate(10deg) scale(1.1);
}

.logo-text {
  font-size: 1.7rem;
  font-weight: 800;
  letter-spacing: -0.5px;
  background: linear-gradient(135deg, #4a74dc 0%, #c33c65 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-shadow: 0 2px 15px rgba(74, 116, 220, 0.3);
  transition: transform 0.3s ease, text-shadow 0.3s ease;
}

.logo-link:hover .logo-text {
  text-shadow: 0 4px 20px rgba(74, 116, 220, 0.5);
  transform: translateY(-1px) scale(1.02);
}

.auth-section {
  position: relative;
  flex: 0 0 auto;
}

.guest-controls {
  display: flex;
  align-items: center;
  gap: 10px;
}

.search-limit-display {
  display: flex;
  align-items: center;
}

.login-button {
  background: linear-gradient(135deg, #4a74dc 0%, #c33c65 100%);
  color: white;
  border: none;
  padding: 0.4rem 0.8rem;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.login-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.user-profile {
  display: flex;
  align-items: center;
  position: relative;
}

.profile-info {
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  padding: 0.4rem;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.profile-info:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.profile-picture {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid rgba(255, 255, 255, 0.3);
}

.user-name {
  color: white;
  font-weight: 500;
  margin-right: 4px;
  font-size: 0.85rem;
}

.profile-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  width: 140px;
  z-index: 200;
  margin-top: 6px;
  overflow: hidden;
}

.menu-item {
  padding: 10px 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #333;
  transition: background-color 0.2s;
  cursor: pointer;
  font-size: 0.85rem;
}

.menu-item:hover {
  background-color: #f5f5f5;
}

@media (max-width: 768px) {
  .navbar-container {
    padding: 1rem;
  }

  .user-name {
    display: none;
  }
}

.loading-spinner {
  width: 16px;
  height: 16px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.upgrade-button {
  background: linear-gradient(135deg, #ffd700 0%, #ff8c00 100%);
  color: #fff;
  border: none;
  padding: 0.4rem 0.8rem;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  margin-right: 10px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  z-index: 10;
}

.upgrade-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(255, 215, 0, 0.3);
}

.premium-badge {
  background: linear-gradient(135deg, #4a74dc 0%, #c33c65 100%);
  color: #fff;
  padding: 0.4rem 0.8rem;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 6px;
  margin-right: 10px;
}

.premium-until {
  font-size: 0.75rem;
  opacity: 0.8;
  margin-left: 4px;
}

.premium-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.4rem;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.1);
}

.mini-spinner {
  width: 14px;
  height: 14px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.verifying-text {
  font-size: 0.7rem;
  opacity: 0.8;
  margin-left: 4px;
}

/* Search limit styles */
.search-limit-badge {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  padding: 2px 8px;
  margin-right: 8px;
  font-size: 0.75rem;
  font-weight: bold;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.reactivate-button {
  background: linear-gradient(135deg, #4caf50 0%, #2e7d32 100%);
}

.reactivate-button:hover {
  background: linear-gradient(135deg, #5cb860 0%, #367d39 100%);
  box-shadow: 0 5px 15px rgba(76, 175, 80, 0.4);
}
</style>
