import axios from "axios";
import GoogleAuthService from "./GoogleAuthService";
import EventBus from "./EventBus";
import { API_URL } from '../config';

class PremiumService {
  constructor() {
    this.premiumStatus = null;
    this.premiumUntil = null;
    this.isCancelled = false;
    this.checkoutUrl = null;
    this.isLoaded = false;
    this.isInRetryMode = false;
    this._verificationMode = false;
    this.verificationTimeout = null;
    this.retryCount = 0;
    this.sessionStorage = window.sessionStorage;

    // Check if we were previously in verification mode
    const verifyingPayment = this.sessionStorage.getItem('verifyingPayment');
    if (verifyingPayment === 'true') {
      this.startVerificationMode();
    }
  }

  // Check if currently in verification mode
  isInVerificationMode() {
    return this._verificationMode;
  }

  // Start verification mode
  startVerificationMode() {
    this._verificationMode = true;
    this.sessionStorage.setItem('verifyingPayment', 'true');
    EventBus.emit('premium-verification-change', true);
    
    // Clear any existing timeout
    if (this.verificationTimeout) {
      clearTimeout(this.verificationTimeout);
    }
    
    // Set timeout to auto-clear verification mode after 30 seconds
    this.verificationTimeout = setTimeout(() => {
      this.stopVerificationMode();
    }, 30000);
  }

  // Stop verification mode
  stopVerificationMode() {
    this._verificationMode = false;
    this.sessionStorage.removeItem('verifyingPayment');
    EventBus.emit('premium-verification-change', false);
    
    if (this.verificationTimeout) {
      clearTimeout(this.verificationTimeout);
      this.verificationTimeout = null;
    }
  }

  // Get premium status
  isPremiumUser() {
    // If we haven't loaded, assume not premium
    if (!this.isLoaded) {
      return false;
    }
    
    // Check if premiumUntil is in the future
    if (this.premiumUntil) {
      const now = new Date();
      const premiumDate = new Date(this.premiumUntil);
      
      // Return true if premiumUntil is in the future, regardless of cancellation status
      // This allows cancelled users to keep access until their premium period ends
      return premiumDate > now;
    }
    
    // Fall back to the flag if no date is available
    return this.premiumStatus === true;
  }

  // Check if subscription is cancelled
  isSubscriptionCancelled() {
    return this.isCancelled === true;
  }

  // Get premium until date
  getPremiumUntil() {
    return this.premiumUntil;
  }

  // Get checkout URL
  getCheckoutUrl() {
    return this.checkoutUrl;
  }

  // Format premium date for display
  formatPremiumDate(date) {
    if (!date) return "";
    const premiumDate = new Date(date);
    return premiumDate.toLocaleDateString();
  }

  // Load premium status from the server
  async loadPremiumStatus() {
    if (!GoogleAuthService.isLoggedIn()) {
      this.premiumStatus = false;
      this.premiumUntil = null;
      this.isCancelled = false;
      this.isLoaded = true;
      return false;
    }

    try {
      const token = GoogleAuthService.getAccessToken();
      const response = await axios.get(`${API_URL}/premium/status`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Update status
      this.premiumStatus = response.data.isPremium;
      this.premiumUntil = response.data.premiumUntil;
      this.isCancelled = response.data.isCancelled || false;
      this.checkoutUrl = response.data.checkoutUrl;
      this.isLoaded = true;

      // Emit event for status change
      EventBus.emit('premium-status-change', this.premiumStatus);
      if (this.isCancelled) {
        EventBus.emit('premium-cancelled', this.premiumUntil);
      }

      // Clear retry mode if we got a successful response
      this.resetRetryState();

      // If we were in verification mode, stop it
      if (this._verificationMode) {
        this.stopVerificationMode();
      }

      return this.premiumStatus;
    } catch (error) {
      console.error("Failed to load premium status:", error);

      // If we're not in retry mode, start it
      if (!this.isInRetryMode) {
        this.startRetryMode();
      }

      return false;
    }
  }

  // Start retry mode for status checking
  startRetryMode() {
    if (this.isInRetryMode) return;
    
    this.isInRetryMode = true;
    this.retryCount = 0;
    this.performRetry();
  }

  // Perform status retry
  async performRetry() {
    if (!this.isInRetryMode || this.retryCount >= 5) {
      this.resetRetryState();
      return;
    }

    // Exponential backoff: 1s, 2s, 4s, 8s, 16s
    const delay = Math.pow(2, this.retryCount) * 1000;
    this.retryCount++;

    setTimeout(async () => {
      await this.loadPremiumStatus();
      
      // If still not successful and under retry limit, try again
      if (!this.isLoaded && this.isInRetryMode && this.retryCount < 5) {
        this.performRetry();
      } else {
        this.resetRetryState();
      }
    }, delay);
  }

  // Reset retry state
  resetRetryState() {
    this.isInRetryMode = false;
    this.retryCount = 0;
  }

  // Redirect to Stripe checkout
  async redirectToCheckout() {
    try {
      const response = await axios.post(`${API_URL}/premium/create-checkout`, {}, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
      });
      
      window.location.href = response.data.checkout_url;
    } catch (error) {
      console.error('Failed to create checkout session:', error);
      throw error;
    }
  }

  // Cancel subscription
  async cancelSubscription() {
    if (!GoogleAuthService.isLoggedIn()) {
      return false;
    }

    try {
      const token = GoogleAuthService.getAccessToken();
      const response = await axios.post(
        `${API_URL}/premium/cancel`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      
      // Reload premium status after cancellation
      await this.loadPremiumStatus();
      
      return response.data.success;
    } catch (error) {
      console.error("Error cancelling subscription:", error);
      return false;
    }
  }

  // Handle successful payment after returning from Stripe
  async handleSuccessfulPayment(sessionId) {
    if (!sessionId || !GoogleAuthService.isLoggedIn()) {
      console.error("Missing session ID or not logged in");
      return false;
    }

    try {
      // console.log(`Processing payment success with session ID: ${sessionId}`);
      const token = GoogleAuthService.getAccessToken();
      
      // Call the premium/success endpoint with the session ID
      const response = await axios.get(
        `${API_URL}/premium/success?session_id=${sessionId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      
      // console.log("Payment success response:", response.data);
      
      // Stop verification mode if it was active
      if (this._verificationMode) {
        this.stopVerificationMode();
      }
      
      // Reload premium status to reflect changes
      await this.loadPremiumStatus();
      
      return response.data.success;
    } catch (error) {
      console.error("Error processing payment success:", error);
      
      // Still reload status to be safe
      await this.loadPremiumStatus();
      
      return false;
    }
  }

  // Reactivate a cancelled subscription
  async reactivateSubscription() {
    if (!GoogleAuthService.isLoggedIn()) {
      return false;
    }

    try {
      const token = GoogleAuthService.getAccessToken();
      const response = await axios.post(
        `${API_URL}/premium/reactivate`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      
      // Reload premium status after reactivation
      await this.loadPremiumStatus();
      
      return response.data.success;
    } catch (error) {
      console.error("Error reactivating subscription:", error);
      return false;
    }
  }
}

export default new PremiumService();
