import EventBus from "./EventBus";

// Event names
const EVENTS = {
  PRIVACY_POLICY: "modal:privacy-policy",
  TERMS_OF_SERVICE: "modal:terms-of-service",
  COOKIE_POLICY: "modal:cookie-policy",
  DMCA: "modal:dmca",
  HOW_IT_WORKS: "modal:how-it-works",
  USER_GUIDE: "modal:user-guide"
};

class ModalService {
  showPrivacyPolicy() {
    EventBus.emit(EVENTS.PRIVACY_POLICY, true);
  }

  hidePrivacyPolicy() {
    EventBus.emit(EVENTS.PRIVACY_POLICY, false);
  }

  showTermsOfService() {
    EventBus.emit(EVENTS.TERMS_OF_SERVICE, true);
  }

  hideTermsOfService() {
    EventBus.emit(EVENTS.TERMS_OF_SERVICE, false);
  }

  showCookiePolicy() {
    EventBus.emit(EVENTS.COOKIE_POLICY, true);
  }

  hideCookiePolicy() {
    EventBus.emit(EVENTS.COOKIE_POLICY, false);
  }

  showDmca() {
    EventBus.emit(EVENTS.DMCA, true);
  }

  hideDmca() {
    EventBus.emit(EVENTS.DMCA, false);
  }

  showHowItWorks() {
    EventBus.emit(EVENTS.HOW_IT_WORKS, true);
  }

  hideHowItWorks() {
    EventBus.emit(EVENTS.HOW_IT_WORKS, false);
  }
  
  showUserGuide() {
    EventBus.emit(EVENTS.USER_GUIDE, true);
  }

  hideUserGuide() {
    EventBus.emit(EVENTS.USER_GUIDE, false);
  }
}

export { EVENTS };
export default new ModalService(); 