import { createApp } from "vue";
import App from "@/App.vue";
import "bootstrap";
import getRouter from "@/router";
import GoogleAuthService from "@/services/GoogleAuthService";
import AuthStore from "@/services/AuthStore";

import "bootstrap/dist/css/bootstrap.min.css";

import VueMixpanel from "vue-mixpanel";

// Initialize AuthStore
AuthStore.initialize();

// Initialize GoogleAuthService on app startup
GoogleAuthService.loadGapi().catch((error) => {
  console.error("Failed to load Google API:", error);
});

const app = createApp(App);

app.config.globalProperties.$WEB = window.WEB;
app.config.globalProperties.$ENDPOINT = "/api";
app.config.globalProperties.$auth = GoogleAuthService;
app.config.globalProperties.$authStore = AuthStore;

app.use(VueMixpanel, {
  token: "ba0904e92ef039812541bfaff60a4e14",
  config: {
    debug: false,
  },
});

const router = getRouter(window.WEB);
app.use(router);
app.mount("#app");
